import React, { useEffect, useRef, useState } from "react";
import * as Realm from "realm-web";
import "./AddStaffForm.css";
import CustomSelect from "../../CustomComponents/CustomSelect/CustomSelect";

function AddStaffForm({ closeAddStaffCallback, userRole }) {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const [dashboardData, setDashboardData] = useState(null);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // references
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const roleRef = useRef();
  const blockRef = useRef();

  // fetch dashboard data
  useEffect(() => {
    console.log(
      "dashboard-data",
      JSON.parse(sessionStorage.getItem("dashboard-data"))
    );
    setDashboardData(JSON.parse(sessionStorage.getItem("dashboard-data")));
  }, []);

  const onClickAddUser = async (e) => {
    // console.table(nameRef.current.value, phoneNumberRef.current.value, emailRef.current.value, roleRef.current.value, passwordRef.current.value, )
    e.preventDefault();
    console.log("block ref", blockRef.current.value);
    const name = nameRef.current.value;
    const email = emailRef.current.value.toLowerCase();
    const phoneNumber = phoneNumberRef.current.value;
    const role = roleRef.current.value;
    const blockName = blockRef.current.value;

    const nameSubstring = name
      .replaceAll(" ", "")
      .substring(0, 4)
      .toLowerCase();
    const phoneNumberSubstring = phoneNumber
      .replaceAll(" ", "")
      .substring(phoneNumber.length - 4);

    const password = nameSubstring + "-" + phoneNumberSubstring;

    try {
      if (name && email && phoneNumber) {
        const res = await app.currentUser.callFunction(
          "adminData/saveAdminUser",
          {
            name,
            email,
            role,
            phoneNumber,
            blockName,
          }
        );
        console.log(
          "user added successfully",
          res
        )(
          role !== "Moderator" &&
            (await app.emailPasswordAuth.registerUser({ email, password }))
        );
        console.log("user registration successful");
      }
    } catch (err) {
      console.error(err.toString());
    }
    closeAddStaffCallback();
  };

  return (
    <div className="d-flex justify-content-center">
      <form class="form shadow-none">
        <p class="title">Add staff </p>
        <p class="message">Signup now and get full access to our app. </p>

        <div className="d-flex justify-content-between">
          <div>
            <div class="input-group mb-3" style={{ width: "100%" }}>
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">
                  Role
                </label>
              </div>
              <select
                ref={roleRef}
                class="custom-select"
                id="inputGroupSelect01"
              >
                {userRole.toLowerCase() != "admin" && (
                  <option value="Admin">Admin</option>
                )}
                {userRole.toLowerCase() != "admin" && (
                  <option value="Head">Head</option>
                )}
                <option value="Moderator">Moderator</option>
              </select>
            </div>
          </div>

          {/* Block Input */}
          <div>
            <div class="input-group mb-3" style={{ width: "100%" }}>
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">
                  Block
                </label>
              </div>
              <select
                ref={blockRef}
                class="custom-select"
                id="inputGroupSelect01"
              >
                {dashboardData &&
                  dashboardData.blockName.map((block) => {
                    return <option value={block}>{block}</option>;
                  })}
              </select>
            </div>
          </div>
        </div>

        <label>
          <input
            onChange={(e) => setName(e.target.value)}
            ref={nameRef}
            required=""
            placeholder=""
            type="text"
            class="input"
          />
          <span>Name</span>
        </label>

        <label>
          <input
            ref={emailRef}
            required=""
            placeholder=""
            type="email"
            class="input"
          />
          <span>Email</span>
        </label>

        <label>
          <input
            onChange={(e) => setPhoneNumber(e.target.value)}
            ref={phoneNumberRef}
            required=""
            placeholder=""
            type="number"
            class="input"
          />
          <span>Phone</span>
        </label>

        {/* <label>
          <input required="" placeholder="" type="date" class="input" />
          <span>DOB</span>
        </label> */}

        <label>
          <span>Default Password</span>
          <span className="mx-3">{`${name
            .replaceAll(" ", "")
            .substring(0, 4)
            .toLowerCase()}-${phoneNumber
            .replaceAll(" ", "")
            .substring(phoneNumber.length - 4)
            .toLowerCase()}`}</span>
        </label>
        <button onClick={onClickAddUser} class="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default AddStaffForm;
