import React from "react";
import MiniDrawer from "../CustomComponents/DashboardPage";
import HomeIcon from "@mui/icons-material/Home";
import HomePage from "../DashboardPages/HomePage";
import ReportPage from "../DashboardPages/ReportPage";
import UpdatePage from "../DashboardPages/UpdatePage";
import ReportIcon from "@mui/icons-material/Report";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LogoutIcon from "@mui/icons-material/Logout";
import DataObjectIcon from "@mui/icons-material/DataObject";
import * as Realm from "realm-web";
import DashboardData from "../DashboardPages/DashboardData";

const drawerSections = [
  // {
  //   name: "Dashboard Data",
  //   logo: <DataObjectIcon fontSize="medium" />,
  //   content: <DashboardData />,
  // },
  {
    name: "Home",
    logo: <HomeIcon fontSize="medium" />,
    content: <HomePage />,
  },
  {
    name: "Report",
    logo: <ReportIcon fontSize="medium" />,
    content: <ReportPage />,
  },
  {
    name: "Update Staff",
    logo: <EditNoteIcon fontSize="medium" />,
    content: <UpdatePage />,
  },
  {
    name: "Logout",
    logo: <LogoutIcon fontSize="medium" />,
    content: HomePage,
  },
];

function Dashboard() {
  const app = new Realm.App({ id: "national-admins-ullyb" });

  return (
    <>
      <MiniDrawer sections={app.currentUser.customData.departmentCode && app.currentUser.customData.canAccessReports
  ? drawerSections
  : drawerSections.filter(section => section.name !== "Report")} />
    </>
  );
}

export default Dashboard;
