import React, { useRef } from "react";
import IconButton from "@mui/material/IconButton";
import PublicIcon from "@mui/icons-material/Public";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Swal from "sweetalert2";
import * as Realm from "realm-web";

function ResolvedComplaintsCard({
  location,
  img,
  resolvedImg,
  date,
  resolvedDate,
  reportId,
  coordinates,
}) {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const reportCardRef = useRef(null);

  function getDaysDifference(first, second) {
    // Get the current date
    const currentDate = new Date(second);

    // Get the date from the date object
    const targetDate = new Date(first);

    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = targetDate - currentDate;

    // Convert milliseconds to days
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(
      differenceInMilliseconds / oneDayInMilliseconds
    );

    return Math.abs(daysDifference);
  }

  return (
    <div
      className="row shadow border-1 rounded-3 my-3 mx-4"
      style={{ width: "45%", border: "4px solid lightgray" }}
      ref={reportCardRef}
    >
      <div
        className="col-8 py-3 rounded-3 d-flex align-items-center"
        style={{ backgroundColor: "#C8C1DA" }}
      >
        <div className="row">
          <div className="col-6">
            <div>
              <center>
                <button
                  className="btn rounded-pill bg-light my-2"
                  style={{ width: "40%", fontSize: ".7rem" }}
                >
                  Old
                </button>
              </center>
            </div>
            <img className="img-fluid rounded-3" src={img} alt="" />
          </div>
          <div className="col-6">
            <div>
              <center>
                <button
                  className="btn rounded-pill bg-light my-2"
                  style={{ width: "40%", fontSize: ".7rem" }}
                >
                  Resolved
                </button>
              </center>
            </div>
            <img className="img-fluid rounded-3" src={resolvedImg} alt="" />
          </div>
        </div>
      </div>

      <div className="col-4 py-3">
        <span className="mb-4">
          <div className="d-flex flex-column justify-content-between px-2">
            {/* <span className="fw-bold fs-3" style={{ color: "#3D1B9D" }}>
              {location}
            </span> */}
            <span className="fw-bold fs-5" style={{ color: "#7E66C4" }}>
              Date :{" "}
              {date.toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="my-3">
            <IconButton
              onClick={() => {
                const mapUrl = `https://www.google.com/maps/place/${coordinates[1]},${coordinates[0]}`;
                window.open(mapUrl);
              }}
              className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
              sx={{
                width: "90%",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "#3D1B9D",
              }}
            >
              <PublicIcon fontSize="medium" />
              View on map
            </IconButton>
          </div>
        </span>

        <div
          className="mt-4 px-2 fw-bold"
          style={{
            color: "#2EAAD8",
          }}
        >
          {`Resolved within ${getDaysDifference(date, resolvedDate)} days`}
        </div>
      </div>
    </div>
  );
}

export default ResolvedComplaintsCard;
