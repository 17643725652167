import React, { useEffect } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Realm from "realm-web";

import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton, Input } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import AddStaffForm from "../Forms/AddstaffForm/AddStaffForm";
import EditStaffForm from "../Forms/EditStaffForm/EditStaffForm";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Swal from "sweetalert2";

const UserRoles = ["Head", "Admin", "Moderator"];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const addStaffFormStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function UpdatePage() {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const [selectedUserForEdit, setSelectedUserForEdit] = React.useState(null);
  const [dashboardData, setDashboardData] = React.useState(null);

  // get dashboard data on load
  useEffect(() => {
    console.log(
      "dashboard-data",
      JSON.parse(sessionStorage.getItem("dashboard-data"))
    );
    setDashboardData(JSON.parse(sessionStorage.getItem("dashboard-data")));
  }, []);
  // useEffect(() => {
  //   const getDashboardData = async () => {
  //     try {
  //       const data = await app.currentUser.callFunction("getDashboardData");
  //       console.log("dashboard data", data);
  //       sessionStorage.setItem("dashboard-data", JSON.stringify(data));
  //       setDashboardData(data);
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };

  //   getDashboardData();
  // }, []);

  // user data states
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    name: "Example Name",
  });

  // admins and moderators states
  const [admins, setAdmins] = React.useState([]);
  const [moderators, setModerators] = React.useState([]);

  // Add staff modal states
  const [addStaffFormOpen, setAddStaffFormOpen] = React.useState(false);
  const handleAddStaffFormOpen = () => setAddStaffFormOpen(true);
  const handleAddStaffFormClose = async () => {
    getAdmins();
    app.currentUser.customData.level4 && getModerators();
    setAddStaffFormOpen(false);
  };

  // Edit staff modal states
  const [editStaffFormOpen, setEditStaffFormOpen] = React.useState(false);
  const handleEditStaffFormOpen = () => setEditStaffFormOpen(true);
  const handleEditStaffFormClose = async () => {
    getAdmins();
    app.currentUser.customData.level4 && getModerators();
    setEditStaffFormOpen(false);
  };

  // tab component states
  const [selectedTab, setSelectedTab] = React.useState("HEADS-ADMINS");
  const handleTabChange = (event, newValue) => {
    console.log("changing value to", newValue);
    setSelectedTab(newValue);
  };
  const getAdmins = async () => {
    try {
      const data = await app.currentUser.callFunction("getAdmins");
      console.log(data);

      setAdmins(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getModerators = async () => {
    try {
      const data = await app.currentUser.callFunction("getModerators");
      console.log(data);

      setModerators(data);
    } catch (err) {
      console.log(err);
    }
  };

  // fetch moderators and admins
  useEffect(() => {
    getAdmins();
    getModerators();
  }, []);

  useEffect(() => {
    try {
      console.log(app.currentUser);
      const newDetails = userDetails;
      newDetails.email = app.currentUser.customData.email;
      newDetails.name = app.currentUser.customData.name;

      setUserDetails(newDetails);
    } catch (err) {
      console.log(err);
    }
  }, []);

  const onClickDelete = async (userData) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await app.currentUser.callFunction(
            "adminData/removeUser",
            {
              type: userData.type,
              email: userData.email,
            }
          );

          console.log("user deleted successfully", res);

          if (userData.type === "ADMIN") getAdmins();
          else getModerators();

          Swal.fire("Deleted!", "User Deleted successfully", "success");
        } catch (err) {
          console.error(err);
        }
      }
    });
  };
  return (
    <div className="bg-white rounded-2 p-4">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <span style={{ color: "#191928" }} className="display-6 fw-bold">
          Update Staff
        </span>

        <span
          className="d-flex align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#F4EFFF", maxWidth: "fit-content" }}
        >
          <AccountCircleIcon
            sx={{ color: "#3D1B9D", marginLeft: "1rem", marginRight: "1rem" }}
            fontSize="large"
          />
          <div className="d-flex flex-column" style={{ marginRight: "1rem" }}>
            <span
              className="fw-bold"
              style={{ color: "#3D1B9D", fontSize: "1.3rem" }}
            >
              {userDetails.name}
            </span>
            <span style={{ color: "#AB97DF" }}>{userDetails.email}</span>
          </div>
        </span>
      </div>

      {/* Add and Search Staff section */}
      {/* Add Staff View Reports */}
      {app.currentUser.customData.canupdateStaff !== false &&
        app.currentUser.customData.departmentCode && (
          <div className="d-flex px-5 my-5 justify-content-between">
            <div>
              <IconButton
                className="rounded-pill py-1 fw-bold fs-5"
                sx={{
                  fontSize: ".9rem",
                  color: "#3D1B9D",
                  gap: "1rem",
                }}
                onClick={handleAddStaffFormOpen}
              >
                <AddCircleIcon fontSize="large" />
                Add Staff
              </IconButton>
            </div>

            {/* Search Bar */}
            {/* <div>
            <SearchBar />
          </div> */}
          </div>
        )}

      {/* Table Section */}
      <div className="my-5 border shadow">
        <Box sx={{ width: "100", backgroundColor: "#3D1B9D" }}>
          <Tabs
            // style={{ float: "left" }}
            value={selectedTab}
            onChange={handleTabChange}
            textColor="white"
            TabIndicatorProps={{
              // Set custom styles for the indicator
              style: {
                height: "5px",
                backgroundColor: "#6E5BA5", // Set your desired color here
                textAlign: "left",
              },
            }}
            variant="fullWidth"
            aria-label="secondary tabs example"
          >
            <Tab
              className=""
              sx={{
                color: "white", // Set the text color to white
                fontSize: "1rem",
                alignSelf: "start",
              }}
              value="HEADS-ADMINS"
              label="HEADS-ADMINS"
            />
            <Tab
              className=""
              sx={{
                color: "white", // Set the text color to white
                fontSize: "1rem",
                alignSelf: "start",
              }}
              value="MODERATORS"
              label="MODERATORS"
            />
          </Tabs>

          {/* Content for each tab */}
          {selectedTab === "HEADS-ADMINS" && (
            <div className="py-5" style={{ backgroundColor: "white" }}>
              {/* Data Table for Admins*/}
              <div className="container mt-4">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  
                    <TableHead
                      sx={{ backgroundColor: "#C8C1DA", color: "#3D1B9D" }}
                    >
                      <TableRow>
                        <TableCell
                          className="fw-bold fs-5"
                          sx={{ color: "#3D1B9D" }}
                          align="center"
                        >
                          S.no.
                        </TableCell>
                        <TableCell
                          className="fw-bold fs-5"
                          sx={{ color: "#3D1B9D" }}
                          align="center"
                        >
                          Area
                        </TableCell>
                        <TableCell
                          className="fw-bold fs-5"
                          sx={{ color: "#3D1B9D" }}
                          align="center"
                        >
                          Email-id
                        </TableCell>
                        <TableCell
                          className="fw-bold fs-5"
                          sx={{ color: "#3D1B9D" }}
                          align="center"
                        >
                          Name
                        </TableCell>
                        {/* <TableCell
                    className="fw-bold fs-5"
                    sx={{ color: "#3D1B9D" }}
                    align="center"
                  >
                    Permission
                  </TableCell> */}
                        <TableCell
                          className="fw-bold fs-5"
                          sx={{ color: "#3D1B9D" }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {admins.length &&
                        admins.map((admin, idx) => {
                          return (
                            <TableRow key={idx}>
                              <TableCell align="center" scope="row">
                                {idx + 1}
                              </TableCell>
                              <TableCell align="center" scope="row">
                                {admin.level4
                                  ? `Block ${admin.level4}`
                                  : admin.level3
                                  ? `Subdivision ${admin.level3}`
                                  : admin.level2
                                  ? `District ${admin.level2}`
                                  : ""}
                              </TableCell>
                              <TableCell align="center" scope="row">
                                {admin.email}
                              </TableCell>
                              <TableCell align="center" scope="row">
                                {admin.name}
                              </TableCell>
                              <TableCell align="center" scope="row">
                                <div className="d-flex justify-content-around">
                                  <IconButton>
                                    <EditIcon
                                      onClick={() => {
                                        setSelectedUserForEdit({
                                          type: "ADMIN",
                                          email: admin.email,
                                        });
                                        handleEditStaffFormOpen();
                                      }}
                                      sx={{ color: "#3D1B9D" }}
                                    />
                                  </IconButton>
                                  <IconButton>
                                    <DeleteIcon
                                      onClick={() =>
                                        onClickDelete({
                                          type: "ADMIN",
                                          email: admin.email,
                                        })
                                      }
                                      sx={{ color: "#E92C2C" }}
                                    />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}

          {selectedTab === "MODERATORS" && (
            <div style={{ backgroundColor: "white" }}>
              <div className="py-5" style={{ backgroundColor: "white" }}>
                {/* tab 2 data */}
                <div className="container mt-4">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead
                        sx={{ backgroundColor: "#C8C1DA", color: "#3D1B9D" }}
                      >
                        <TableRow>
                          <TableCell
                            className="fw-bold fs-5"
                            sx={{ color: "#3D1B9D" }}
                            align="center"
                          >
                            S.no.
                          </TableCell>
                          <TableCell
                            className="fw-bold fs-5"
                            sx={{ color: "#3D1B9D" }}
                            align="center"
                          >
                            Area
                          </TableCell>
                          <TableCell
                            className="fw-bold fs-5"
                            sx={{ color: "#3D1B9D" }}
                            align="center"
                          >
                            Email-id
                          </TableCell>
                          <TableCell
                            className="fw-bold fs-5"
                            sx={{ color: "#3D1B9D" }}
                            align="center"
                          >
                            Name
                          </TableCell>
                          {/* <TableCell
                    className="fw-bold fs-5"
                    sx={{ color: "#3D1B9D" }}
                    align="center"
                  >
                    Permission
                  </TableCell> */}
                          <TableCell
                            className="fw-bold fs-5"
                            sx={{ color: "#3D1B9D" }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {moderators.length &&
                          moderators.map((moderator, idx) => {
                            return (
                              <TableRow key={idx}>
                                <TableCell align="center" scope="row">
                                  {idx + 1}
                                </TableCell>
                                <TableCell align="center" scope="row">
                                  {moderator.level4
                                    ? `Block ${moderator.level4}`
                                    : moderator.level3
                                    ? `Subdivision ${moderator.level3}`
                                    : moderator.level2
                                    ? `District ${moderator.level2}`
                                    : ""}
                                </TableCell>
                                <TableCell align="center" scope="row">
                                  {moderator.email}
                                </TableCell>
                                <TableCell align="center" scope="row">
                                  {moderator.name}
                                </TableCell>
                                <TableCell align="center" scope="row">
                                  <div className="d-flex justify-content-around">
                                    <IconButton>
                                      <EditIcon
                                        onClick={() => {
                                          setSelectedUserForEdit({
                                            type: "MODERATOR",
                                            email: moderator.email,
                                          });
                                          handleEditStaffFormOpen();
                                        }}
                                        sx={{ color: "#3D1B9D" }}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <DeleteIcon
                                        onClick={() =>
                                          onClickDelete({
                                            type: "MODERATOR",
                                            email: moderator.email,
                                          })
                                        }
                                        sx={{ color: "#E92C2C" }}
                                      />
                                    </IconButton>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          )}
        </Box>
      </div>

      {/* Add User Form  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addStaffFormOpen}
        onClose={handleAddStaffFormClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addStaffFormOpen}>
          <Box sx={addStaffFormStyles}>
            <AddStaffForm
              closeAddStaffCallback={handleAddStaffFormClose}
              userRole={app.currentUser.customData.role}
            />
          </Box>
        </Fade>
      </Modal>

      {/* Edit Staff Form */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editStaffFormOpen}
        onClose={handleEditStaffFormClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editStaffFormOpen}>
          <Box sx={addStaffFormStyles}>
            <EditStaffForm
              userDetails={selectedUserForEdit}
              closeEditStaffCallback={async () => {
                // getAdmins()
                // getModerators()
                handleEditStaffFormClose();
              }}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

function CustomSelect({ name, options }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(UserRoles[0]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={personName}
          sx={{
            color: "#3D1B9D",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3D1B9D",
              },
              "&:hover fieldset": {
                borderColor: "#3D1B9D",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3D1B9D",
              },
            },
          }}
          onChange={handleChange}
          input={<Input label={name} />}
          MenuProps={MenuProps}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

function SearchBar() {
  return (
    <Paper
      component="form"
      className="rounded-pill"
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: 400,
        border: "2px solid #3D1B9D",
      }}
    >
      <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
        <SearchIcon sx={{ color: "#3D1B9D" }} />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1, color: "#3D1B9D" }}
        placeholder="Search Role"
        inputProps={{ "aria-label": "search google maps" }}
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    </Paper>
  );
}
export default UpdatePage;
