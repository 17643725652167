import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import * as Realm from "realm-web";

function DashboardData() {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    name: "Example Name",
  });

  // get user details on load
  useEffect(() => {
    try {
      console.log(app.currentUser);
      const newDetails = userDetails;
      newDetails.email = app.currentUser.customData.email;
      newDetails.name = app.currentUser.customData.name;

      setUserDetails(newDetails);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return (
    <div className="bg-white rounded-2 p-4">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <span style={{ color: "#191928" }} className="display-6 fw-bold">
          Dashboard
        </span>

        <span
          className="d-flex align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#F4EFFF", maxWidth: "fit-content" }}
        >
          <AccountCircleIcon
            sx={{ color: "#3D1B9D", marginLeft: "1rem", marginRight: "1rem" }}
            fontSize="large"
          />
          <div className="d-flex flex-column" style={{ marginRight: "1rem" }}>
            <span
              className="fw-bold"
              style={{ color: "#3D1B9D", fontSize: "1.3rem" }}
            >
              {userDetails.name}
            </span>
            <span style={{ color: "#AB97DF" }}>{userDetails.email}</span>
          </div>
        </span>
      </div>
    </div>
  );
}

export default DashboardData;
