import React, { useRef, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import PublicIcon from "@mui/icons-material/Public";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import Swal from "sweetalert2";
import * as Realm from "realm-web";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InfoIcon from "@mui/icons-material/Info";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";


// import { MenuItem, Select } from "@mui/material";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function ActiveComplaintCard({
  dashboardData,
  allData,
  attending,
  address,
  location,
  img,
  date,
  reportId,
  coordinates,
}) {
  const [newDepartment, setNewDepartment] = React.useState();
  const [newProblem, setNewProblem] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [openUpdateModal, setOpenUpdateModal] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdateModalOpen = () => setOpenUpdateModal(true);
  const handleUpdateModalClose = () => setOpenUpdateModal(false);

  // console.log("dashboard Data", dashboardData);

  const app = new Realm.App({ id: "national-admins-ullyb" });
  const activeComplaintCardRef = useRef(null);

  function getDaysDifference(dateObject) {
    // Get the current date
    const currentDate = new Date();

    // Get the date from the date object
    const targetDate = new Date(dateObject);

    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = currentDate - targetDate;

    // Convert milliseconds to days
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(
      differenceInMilliseconds / oneDayInMilliseconds
    );
   

    return daysDifference;
  }

  const transferComplaint = async () => {
    // e.preventDefault()
    try {
      // Swal.fire({
      //   title: "Are you sure?",
      //   text: "Transfer Complaint",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   cancelButtonColor: "#d33",
      //   confirmButtonText: "Yes, transfer complaint",
      // }).then(async (result) => {
      //   setOpenUpdateModal(false)
      //   if (result.isConfirmed) {

      //   }
      // });

      handleUpdateModalClose();
      try {
        if (!newDepartment || !newProblem)
          throw new Error("Department or problem not selected");
        const newDepartmentCode =
          dashboardData.allDepartmentNames[newDepartment];
        const newProblemCode =
          dashboardData.allComplaintTypes[newDepartmentCode][newProblem];
        const res = await app.currentUser.callFunction("transferComplaint", {
          _id: reportId,
          departmentCode: newDepartmentCode,
          newProblemCode,
          oldProblemCode: allData.strCode,
        });
         // remove the element
         activeComplaintCardRef.current.classList.add("d-none");

        Swal.fire("Updated!", "Complaint Successfully Transfered", "success");
      } catch (error) {
        Swal.fire("Error!", "Some error occured!", "error");
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <div
      className="row shadow border-1 rounded-3 my-3 mx-4"
      style={{ maxWidth: "45%", border: "4px solid lightgray" }}
      ref={activeComplaintCardRef}
    >
      <div
        className="col-5 py-3 rounded-3 d-flex align-items-center"
        style={{ backgroundColor: "#C8C1DA" }}
      >
        <img className="img-fluid rounded-3" src={img} alt="" />
      </div>
      <div className="col-7 py-3">
        <span className="mb-4">
          <div className="d-flex justify-content-between align-items-center px-2">
            {/* <span className="fw-bold fs-3" style={{ color: "#3D1B9D" }}>
              {location}
            </span> */}
            <span className="fw-bold fs-5" style={{ color: "#7E66C4" }}>
              Date :{" "}
              {date != null
                ? date.toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "NA"}
            </span>
            <IconButton
              className="rounded-pill py-1 shadow-sm"
              sx={{
                width: "fit-content",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "",
              }}
              onClick={handleUpdateModalOpen}
            >
              <ReplyIcon className="mx-1" />
            </IconButton>
          </div>
          <div className="my-3">
            <IconButton
              onClick={() => {
                const mapUrl = `https://www.google.com/maps/place/${coordinates[1]},${coordinates[0]}`;
                window.open(mapUrl);
              }}
              className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
              sx={{
                width: "90%",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "#3D1B9D",
              }}
            >
              <PublicIcon fontSize="medium" />
              View on map
            </IconButton>
          </div>

          <div className="my-3">
            <IconButton
              className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
              sx={{
                width: "90%",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "#E71E1E",
              }}
              onClick={async () => {
                try {


                 
                  (allData?.reportCount === undefined) && Swal.fire({
                    title: 'Give Reason',
                    input: 'text',
                    inputLabel: 'Tell people the reason you reporting this post, this will be visible to all public. Use keyword "vulgar" if the image contains vulagrity and cannot be shown to users.',
                    showCancelButton: true,
                    confirmButtonText: 'Continue to report',
                    cancelButtonText: 'Cancel',
                  }).then(async (result) => {
                    if (result.isConfirmed) {
                      // Handle the input value (result.value) here
                      const userInput = result.value;

                      if(userInput){
                        try{
                          let result1 = await app.currentUser.callFunction("reportPost", {
                           _id: reportId , reason : userInput
                         })
                         Swal.fire("Reported!", "Reported successfully", "success");
   
                       }catch(err){
                        console.log(err)
                         Swal.fire("Error!", "Some error occured!!", "error");
                       }

                      }
                      else{
                        Swal.fire("Error!", "Please write description", "error");
                      }
                      
                    }
                  
                    
                  });
               
                 
                  

                  // remove the element
                  // console.log(reportCardRef.current.classList);
                  // reportCardRef.current.classList.add("d-none");
                } catch (error) {
                  Swal.fire("Error!", "Some error occured!", "error");
                }
              }}
            >
              <FlagOutlinedIcon fontSize="medium" />
              {(allData?.reportCount  !== undefined) ? "already reported": "report"} 
            </IconButton>
          </div>
        </span>
        <div className="px-2">{address}</div>
        <div
          className="mt-4 px-2 fw-bold"
          style={{
            color: "red",
          }}
        >
          {`Pending from ${getDaysDifference(date)} days`}
        </div>

        {attending ? (
          <span className="btn btn-success mt-3">Attending</span>
        ) : (
          <span className="btn btn-danger mt-3">Not Attending</span>
        )}

        <div className="mt-3">
          <IconButton
            className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
            sx={{
              width: "50%",
              border: "1.5px solid gray",
              fontSize: ".5rem",
              marginRight: "1rem",
            }}
            onClick={handleOpen}
          >
            <InfoIcon className="mx-1" /> Get Details
          </IconButton>
        </div>

        {/* <div className="mt-3">
          
        </div> */}
      </div>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <span className="fs-4 fw-bold">Uploader details</span>
            <span className="fs-6 d-block">
              {allData.secretEmail || allData.secretPhone}
            </span>
          </div>

          <div>
            <span className="fs-4 fw-bold">Attending:</span>
            <span className="fs-6 d-block">
              {allData.attending ? allData.attending : "None"}
            </span>
          </div>

          <div>
            <span className="fs-4 fw-bold">Description:</span>
            <span className="fs-6 d-block">
              {allData.description ? allData.description : "None"}
            </span>
          </div>

          <div>
            <span className="fs-4 fw-bold">Attending Date:</span>
            <span className="fs-6 d-block">
              {allData.attendingDate
                ? allData.attendingDate.toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "NA"}
            </span>
          </div>
        </Box>
      </Modal>

      {/* update department or problem dialog */}
      <Modal
        open={openUpdateModal}
        onClose={handleUpdateModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-center">
            <div class="form shadow-none">
              <p class="title">Transfer Complaint</p>
              {/* <p class="message">Signup now and get full access to our app. </p> */}

              {/* <div>
                <select
                  class="form-select form-select-md mb-3"
                  aria-label=".form-select-lg"
                >
                  <option selected>Select Department</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div> */}

              <FormControl variant="filled" fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newDepartment}
                  label="Department"
                  onChange={(e) => {
                    setNewDepartment(e.target.value);
                  }}
                >
                  {dashboardData.allDepartmentNames &&
                    Object.keys(dashboardData.allDepartmentNames).map(
                      (name) => {
                        return <MenuItem value={name}>{name}</MenuItem>;
                      }
                    )}
                </Select>

                <InputLabel id="demo-simple-select-label">Problem</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={newProblem}
                  label="Problem"
                  onChange={(e) => setNewProblem(e.target.value)}
                >
                  {newDepartment &&
                    Object.keys(
                      dashboardData.allComplaintTypes[
                        dashboardData.allDepartmentNames[newDepartment]
                      ]
                    ).map((name) => {
                      return <MenuItem value={name}>{name}</MenuItem>;
                    })}
                </Select>
              </FormControl>
              <div className="d-flex justify-content-end">
                <button
                  onClick={transferComplaint}
                  class="btn mx-3"
                  style={{ backgroundColor: "#3D1B9D", color: "white" }}
                >
                  Update
                </button>
                <button
                  onClick={handleUpdateModalClose}
                  class="submit"
                  className="btn btn-danger"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ActiveComplaintCard;
