import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Realm from "realm-web";
import ReportCard from "../ReportCard/ReportCard";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { useLocation, useNavigate } from "react-router";
import Swal from "sweetalert2";
import ResolvedComplaintsCard from "../ResolvedComplaintsCard";

function ResolvedComplaints() {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const location = useLocation();

  // Parse the query parameters
  const queryParams = new URLSearchParams(location.search);

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    name: "Example Name",
  });
  const [reportsData, setReportsData] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const dashboardData = JSON.parse(sessionStorage.getItem("dashboard-data"));
  const departmentCode = app.currentUser.customData.departmentCode
    ? Object.values(app.currentUser.customData.departmentCode)[0]
    : queryParams.get("departmentCode");
  const problems = dashboardData.complaintTypes[departmentCode];

  useEffect(() => {
    try {
      console.log(app.currentUser);
      const newDetails = userDetails;
      newDetails.email = app.currentUser.customData.email;
      newDetails.name = app.currentUser.customData.name;

      setUserDetails(newDetails);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // fetch reports data
  useEffect(() => {
    const fetchReportsData = async () => {
      const res = await app.currentUser.callFunction("getResolvedComplaints", {
        strCode: selectedProblem ? problems[selectedProblem] : "-1",
        departmentCode
      });
      console.log("reports fetch res", res);
      setReportsData(res);
    };

    fetchReportsData();
  }, [selectedProblem]);

  return (
    <div className="p-3 bg-white rounded-2 p-4">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <span className="d-flex align-items-center">
          <button
            className="btn text-center mx-2"
            style={{ backgroundColor: "#3D1B9D" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <KeyboardBackspaceOutlinedIcon style={{ color: "white" }} />
          </button>
          <span style={{ color: "#191928" }} className="display-6 fw-bold">
            Resolved Complaints
          </span>
        </span>

        <span
          className="d-flex align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#F4EFFF", maxWidth: "fit-content" }}
        >
          <AccountCircleIcon
            sx={{ color: "#3D1B9D", marginLeft: "1rem", marginRight: "1rem" }}
            fontSize="large"
          />
          <div className="d-flex flex-column" style={{ marginRight: "1rem" }}>
            <span
              className="fw-bold"
              style={{ color: "#3D1B9D", fontSize: "1.3rem" }}
            >
              {userDetails.username}
            </span>
            <span style={{ color: "#AB97DF" }}>{userDetails.email}</span>
          </div>
        </span>
      </div>

      <div className="d-flex justify-content-start mt-3">
        <CustomSelect
          name={"Problem"}
          setValue={setSelectedProblem}
          defaultValue={problems ? Object.keys(problems)[0] : ""}
          options={problems ? Object.keys(problems) : []}
        />
      </div>

      <div className="reportsContainer d-flex flex-wrap justify-content-start my-3">
        {reportsData &&
          reportsData.map((report) => {
            return (
              <ResolvedComplaintsCard
                key={report.uid}
                location={report.level2}
                date={report.date}
                resolvedDate={report.resolvedDate}
                img={report.imageUrl}
                resolvedImg={report.resolvedImageUrl}
                reportId={report._id}
                coordinates={report.coords}
              />
            );
          })}
      </div>
    </div>
  );
}

export default ResolvedComplaints;
