import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Realm from "realm-web";
import ReportCard from "../ReportCard/ReportCard";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";
import Swal from "sweetalert2";

function ReportPage() {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    name: "Example Name",
  });
  const [reportsData, setReportsData] = useState([]);
  const [selectedProblem, setSelectedProblem] = useState(null);
  const dashboardData = JSON.parse(sessionStorage.getItem("dashboard-data"));
  const departmentCode = Object.values(
    app.currentUser.customData.departmentCode
  )[0];
  const problems = dashboardData.complaintTypes[departmentCode];
  console.log("problems", problems);

  useEffect(() => {
    try {
      console.log(app.currentUser);
      const newDetails = userDetails;
      newDetails.email = app.currentUser.customData.email;
      newDetails.name = app.currentUser.customData.name;

      setUserDetails(newDetails);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // fetch reports data
  useEffect(() => {
    const fetchReportsData = async () => {
      const res = await app.currentUser.callFunction(
        "reports/getActiveReports",
        { strCode: selectedProblem ? problems[selectedProblem] : "-1" }
      );
      console.log("reports fetch res", res.result);
      setReportsData(res.result);
    };

    fetchReportsData();
  }, [selectedProblem]);

  return (
    <div className="p-3 bg-white rounded-2 p-4">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <span style={{ color: "#191928" }} className="display-6 fw-bold">
          Reports
        </span>

        <span
          className="d-flex align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#F4EFFF", maxWidth: "fit-content" }}
        >
          <AccountCircleIcon
            sx={{ color: "#3D1B9D", marginLeft: "1rem", marginRight: "1rem" }}
            fontSize="large"
          />
          <div className="d-flex flex-column" style={{ marginRight: "1rem" }}>
            <span
              className="fw-bold"
              style={{ color: "#3D1B9D", fontSize: "1.3rem" }}
            >
              {userDetails.username}
            </span>
            <span style={{ color: "#AB97DF" }}>{userDetails.email}</span>
          </div>
        </span>
      </div>

      <div className="d-flex justify-content-start mt-3">
        <CustomSelect
          name={"Problem"}
          setValue={setSelectedProblem}
          defaultValue={problems ? Object.keys(problems)[0] : ""}
          options={problems ? Object.keys(problems) : []}
        />
      </div>

      <div className="reportsContainer d-flex flex-wrap justify-content-start my-3">
        {reportsData.length > 0 ? (
          reportsData.map((report) => {
            return (
              <ReportCard
                allData={report}
                key={report.uid}
                location={report.level2}
                date={report.date}
                img={report.imageUrl}
                reportId={report._id}
                coordinates={report.coords}
              />
            );
          })
        ) : (
          <div className="fs-2 fw-bold">No Reports Found!</div>
        )}
      </div>

      <div className="container my-4 d-flex justify-content-end">
        <button
          className="rounded-pill bg-light py-1 fw-bold shadow px-5"
          style={{
            border: "2px solid #E92C2C",
            fontSize: "20px",
            color: "#E92C2C",
          }}
          onClick={() => {
            Swal.fire({
              title: "Are you sure?",
              text: "You won't be able to revert this!",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes, delete all reports!",
            }).then(async (result) => {
              if (result.isConfirmed) {
                try {
                  const res = await app.currentUser.callFunction(
                    "reports/deleteCompaintsFromMap",
                    {
                      ids: reportsData.map((report) => {
                        return {
                          id: report._id,
                          strCode: problems[selectedProblem],
                        };
                      }),
                    }
                  );
                  console.log("deleted report res", res);
                  setReportsData([]);
                  Swal.fire(
                    "Deleted!",
                    "All reports removed successfully",
                    "success"
                  );
                } catch (error) {
                  Swal.fire("Error!", "Some error occured!", "error");
                }
              }
            });
          }}
        >
          Delete All Reported complaints
        </button>
      </div>
    </div>
  );
}

export default ReportPage;
