import * as React from "react";
import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const CustomSelect = ({ name, options, setValue, defaultValue }) => {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState(
    defaultValue
  );
  
  React.useEffect(() => {
    console.log('default value', name, defaultValue)
    setPersonName(defaultValue)
    if(setValue) setValue(defaultValue)
  }, [])

  // console.log(`${name} dropdown options`, options)
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );

    if(setValue) setValue(value)
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel
          sx={{
            color: "#3D1B9D",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3D1B9D",
              },
              "&:hover fieldset": {
                borderColor: "#3D1B9D",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3D1B9D",
              },
            },
          }}
          id="demo-simple-select-disabled-label"
        >
          {name}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={personName}
          sx={{
            color: "#3D1B9D",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3D1B9D",
              },
              "&:hover fieldset": {
                borderColor: "#3D1B9D",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3D1B9D",
              },
            },
          }}
          onChange={handleChange}
          variant="fullWidth"
          input={<OutlinedInput label={name} />}
        >
          {options.length > 0 && options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, personName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelect;
