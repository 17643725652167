import React, { useRef } from "react";
import IconButton from "@mui/material/IconButton";
import PublicIcon from "@mui/icons-material/Public";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import InfoIcon from "@mui/icons-material/Info";
import Swal from "sweetalert2";
import * as Realm from "realm-web";
import { Box, Modal } from "@mui/material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


function ReportCard({ allData, location, img, date, reportId, coordinates }) {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const reportCardRef = useRef(null);

  function getDaysDifference(dateObject) {
    // Get the current date
    const currentDate = new Date();

    // Get the date from the date object
    const targetDate = new Date(dateObject);

    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = targetDate - currentDate;

    // Convert milliseconds to days
    const oneDayInMilliseconds = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(
      differenceInMilliseconds / oneDayInMilliseconds
    );

    return daysDifference;
  }

  return (
    <div
      className="row shadow border-1 rounded-3 my-3 mx-4"
      style={{ maxWidth: "45%", border: "4px solid lightgray" }}
      ref={reportCardRef}
    >
      <div
        className="col-5 py-3 rounded-3 d-flex align-items-center"
        style={{ backgroundColor: "#C8C1DA" }}
      >
        <img className="img-fluid rounded-3" src={img} alt="" />
      </div>
      <div className="col-7 py-3">
        <span className="mb-4">
          <div className="d-flex justify-content-between align-items-center">
            <span className="fw-bold fs-3" style={{ color: "#3D1B9D" }}>
              {location}
            </span>
            <span className="fw-bold fs-5" style={{ color: "#7E66C4" }}>
              {date.toLocaleString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="my-3">
            <IconButton
              onClick={() => {
                const mapUrl = `https://www.google.com/maps/place/${coordinates[1]},${coordinates[0]}`;
                window.open(mapUrl);
              }}
              className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
              sx={{
                width: "90%",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "#3D1B9D",
              }}
            >
              <PublicIcon className="mx-1" fontSize="medium" />
              View on map
            </IconButton>
          </div>

          <div className="my-3">
            <IconButton
              className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
              sx={{
                width: "90%",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "#E71E1E",
              }}
              onClick={() => {
                Swal.fire({
                  title: "Are you sure?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, delete it!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    try {
                      const res = await app.currentUser.callFunction(
                        "reports/removeFalseReport",
                        {
                          _id: reportId,
                        }
                      );
                      console.log("deleted report res", res);
                      Swal.fire(
                        "Deleted!",
                        "Report removed successfully",
                        "success"
                      );

                      // remove the element
                      console.log(reportCardRef.current.classList);
                      reportCardRef.current.classList.add("d-none");
                    } catch (error) {
                      Swal.fire("Error!", "Some error occured!", "error");
                    }
                  }
                });
              }}
            >
              <RemoveCircleOutlineIcon className="mx-1" fontSize="medium" />
              Reoprted by mistke
            </IconButton>
          </div>

          {/* Get Details  */}
          <div className="my-3">
            <IconButton
              className="rounded-pill py-1 fw-bold fs-6 shadow-sm"
              sx={{
                width: "90%",
                border: "1.5px solid gray",
                fontSize: ".5rem",
                color: "",
              }}
              onClick={handleOpen}
            >
              <InfoIcon className="mx-1" /> Get Details
            </IconButton>
          </div>
        </span>
        {getDaysDifference(date) >= 7 && (
          <div
            className="mt-4 rounded-pill fw-bolder px-3"
            style={{
              maxWidth: "fit-content",
              color: "white",
              backgroundColor: "red",
            }}
          >
            PAST DUE DATE
          </div>
        )}
      </div>

      {/* Modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <span className="fs-4 fw-bold">Uploader details</span>
            <span className="fs-6 d-block">
              {allData.secretEmail || allData.secretPhone}
            </span>
          </div>

          <div>
            <span className="fs-4 fw-bold">Attending:</span>
            <span className="fs-6 d-block">
              {allData.attending ? allData.attending : "None"}
            </span>
          </div>

          <div>
            <span className="fs-4 fw-bold">Description:</span>
            <span className="fs-6 d-block">
              {allData.description ? allData.description : "None"}
            </span>
          </div>

          <div>
            <span className="fs-4 fw-bold">Attending Date:</span>
            <span className="fs-6 d-block">
              {allData.attendingDate
                ? allData.attendingDate.toLocaleString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "NA"}
            </span>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default ReportCard;
