import * as React from "react";
import * as Realm from "realm-web";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import HomePage from "../DashboardPages/HomePage";
import UpdatePage from "../DashboardPages/UpdatePage";
import one_app_logo from "../../images/logoSvg.svg";
import { Divider } from "@mui/material";
import { Navigate, useNavigate } from "react-router";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ sections }) {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState(<HomePage />);
  const [sectionContent, setSectionContent] = React.useState({});

  const navigate = useNavigate();

  console.log('drawer', sections)
  React.useState(() => {
    const data = {};
    sections.forEach((section) => {
      data[section.name.toLowerCase()] = section.content;
    });
    setSectionContent(data);
  }, [sections]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutUser = async () => {
    const app = new Realm.App({ id: "national-admins-ullyb" });
    app.currentUser.logOut().then((res) => {
      sessionStorage.clear();
      window.location.reload();
    });
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {!open ? (
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="center"
              sx={{
                color: "#3D1B9D",
                marginRight: 0.5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon sx={{ color: "#3D1B9D" }} />
              ) : (
                <ChevronLeftIcon sx={{ color: "#3D1B9D" }} />
              )}
            </IconButton>
          )}
        </DrawerHeader>
        <List>
          {sections.map((section, idx) => (
            <ListItem key={idx} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                id={`${section.name.split(" ").join("")}-sidebar-btn`}
                onClick={() =>
                  section.name.toLowerCase() !== "logout"
                    ? setContent(sectionContent[section.name.toLowerCase()])
                    : logoutUser()
                }
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    color: "#3D1B9D",
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {section.logo}
                </ListItemIcon>
                <ListItemText
                  primary={section.name}
                  sx={{ opacity: open ? 1 : 0, color: "#3D1B9D" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          pt: 0,
          backgroundColor: "#C8C1DA",
          height: "100vh",
        }}
      >
        <DrawerHeader />
        <Typography paragraph>{content}</Typography>
      </Box>
    </Box>
  );
}
