import React, { useEffect, useRef } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import * as Realm from "realm-web";
import CustomSelect from "../CustomComponents/CustomSelect/CustomSelect";

import { useTheme } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OutlinedInput } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function HomePage() {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const navigate = useNavigate();
  const accessData = app.currentUser.customData;
  const [dashboardData, setDashboardData] = React.useState({});

  const [departmentDropdownValue, setDepartmentDropdownValue] =
    React.useState("");
  const [problemDropdownValue, setProblemDropdownValue] = React.useState("");
  const [timePeriodDropdownValue, setTimePeriodDropdownValue] =
    React.useState("");

  const [overviewsData, setOverviewsData] = React.useState(null);

  // get dashboard data on load
  useEffect(() => {
    const getDashboardData = async () => {
      try {
        const data = await app.currentUser.callFunction("getDashboardData");
        console.log("dashboard data", data);
        sessionStorage.setItem("dashboard-data", JSON.stringify(data));
        setDashboardData(data);
      } catch (err) {
        console.log(err);
      }
    };

    getDashboardData();
  }, []);

  // console.log("access-data", accessData);
  const [userDetails, setUserDetails] = React.useState({
    email: "",
    name: "Example Name",
  });

  // get user details on load
  useEffect(() => {
    try {
      console.log(app.currentUser);
      const newDetails = userDetails;
      newDetails.email = app.currentUser.customData.email;
      newDetails.name = app.currentUser.customData.name;

      setUserDetails(newDetails);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // get dashboard data on load
  useEffect(() => {
    console.log(
      "dashboard-data",
      JSON.parse(sessionStorage.getItem("dashboard-data"))
    );
    setDashboardData(JSON.parse(sessionStorage.getItem("dashboard-data")));
  }, []);

  // get overview table data
  const getOverviewData = async () => {
    try {
      if (departmentDropdownValue && problemDropdownValue) {
        console.log("getting overview data");
        const departmentCode =
          dashboardData.departmentNames[departmentDropdownValue];
        const data = await app.currentUser.callFunction("getOverviews", {
          // level1: accessData.level1,
          // level2: accessData.level2,
          // level3: accessData.level3,
          level4: accessData.level4,
          departmentCode,
          problemCode:
            dashboardData.complaintTypes[departmentCode][problemDropdownValue],
          timePeriod: timePeriodDropdownValue
            ? timePeriodDropdownValue == "Today"
              ? timePeriodDropdownValue
              : timePeriodDropdownValue.split(" ")[1]
            : "Today",
        });

        console.log("get-overviews-data", data);
        setOverviewsData(data);

        // if(timePeriodDropdownValue != "Today") console.log(timePeriodDropdownValue.split(" ")[1])
        // else console.log(timePeriodDropdownValue)

        // console.log('department selected code value', dashboardData.departmentNames[departmentDropdownValue]);
        // console.log('problem selected code value', dashboardData.complaintTypes[problemDropdownValue]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   getOverviewData();
  // }, [departmentDropdownValue, problemDropdownValue, timePeriodDropdownValue]);

  const openInNewWindow = (newPath) => {
    // Get the current URL
    const currentURL = new URL(window.location.href);

    // Extract URL components
    const protocol = currentURL.protocol;
    const hostname = currentURL.hostname;
    const port = currentURL.port;

    // Construct the new URL with the provided path
    let newURL = `${protocol}//${hostname}:${port}${newPath}`;

    // add department code if not in custom data
    if (dashboardData && app.currentUser.customData.departmentCode == null) {
      newURL += `?departmentCode=${dashboardData.departmentNames[departmentDropdownValue]}`;
    }
    // Open the new URL in a new window
    window.open(newURL, "_blank");
  };
  return (
    <div className="bg-white rounded-2 p-4">
      {/* Header */}
      <div className="d-flex justify-content-between">
        <span style={{ color: "#191928" }} className="display-6 fw-bold">
          Dashboard
        </span>

        <span
          className="d-flex align-items-center p-2 rounded-2"
          style={{ backgroundColor: "#F4EFFF", maxWidth: "fit-content" }}
        >
          <AccountCircleIcon
            sx={{ color: "#3D1B9D", marginLeft: "1rem", marginRight: "1rem" }}
            fontSize="large"
          />
          <div className="d-flex flex-column" style={{ marginRight: "1rem" }}>
            <span
              className="fw-bold"
              style={{ color: "#3D1B9D", fontSize: "1.3rem" }}
            >
              {userDetails.name}
            </span>
            <span style={{ color: "#AB97DF" }}>{userDetails.email}</span>
          </div>
        </span>
      </div>

      {/* Dropdown section */}
      <div className="d-flex justify-content-start align-items-center flex-wrap my-3">
        <CustomSelect
          setValue={setDepartmentDropdownValue}
          name={"Department"}
          defaultValue={
            dashboardData != null
              ? dashboardData.departmentNames
                ? dashboardData.departmentNames[0]
                : ""
              : ""
          }
          options={
            dashboardData != null
              ? dashboardData.departmentNames
                ? Object.keys(dashboardData.departmentNames)
                : []
              : ""
          }
        />
        <CustomSelect
          setValue={setProblemDropdownValue}
          name={"Problem"}
          defaultValue={
            dashboardData != null
              ? dashboardData.complaintTypes
                ? Object.keys(dashboardData.complaintTypes)[0]
                : ""
              : ""
          }
          options={
            dashboardData != null
              ? dashboardData.complaintTypes
                ? departmentDropdownValue
                  ? Object.keys(
                      dashboardData.complaintTypes[
                        dashboardData.departmentNames[departmentDropdownValue]
                      ]
                    )
                  : []
                : []
              : ""
          }
        />
        {/* <CustomSelect
          name={"Sub-district"}
          defaultValue={
            dashboardData.subDivison ? dashboardData.subDivison[0] : ""
          }
          options={
            dashboardData.subDivison
              ? Object.values(dashboardData.subDivison)
              : []
          }
        /> */}
        <CustomSelect
          name={"Block"}
          defaultValue={
            dashboardData != null
              ? dashboardData.blockName
                ? dashboardData.blockName[0]
                : ""
              : ""
          }
          options={
            dashboardData != null
              ? dashboardData.blockName
                ? Object.values(dashboardData.blockName)
                : []
              : ""
          }
        />

        <button
          className="rounded-4 py-1 fw-bold shadow"
          style={{
            backgroundColor: "#3D1B9D",
            color: "white",
            border: "2px solid gray",
            width: "150px",
            height: "50px",
          }}
          onClick={getOverviewData}
        >
          Search
        </button>
      </div>

      {/* Overview Section */}
      <div className="my-5">
        <div className="fs-2">Overview</div>

        <div className="container my-3">
          <CustomSelect
            setValue={setTimePeriodDropdownValue}
            defaultValue={"Today"}
            name={"Time Selection"}
            // options={["Today", "This Week", "This Month", "This Year"]}
            options={["Today"]}
          />
        </div>

        {/* Data Table */}
        <div className="container mt-4">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#C8C1DA", color: "#3D1B9D" }}>
                <TableRow>
                  <TableCell
                    className="fw-bold"
                    sx={{ color: "#3D1B9D" }}
                    align="center"
                  >
                    Total Active Complaints
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    sx={{ color: "#3D1B9D" }}
                    align="center"
                  >
                    Total Complaints Received
                  </TableCell>
                  <TableCell
                    className="fw-bold"
                    sx={{ color: "#3D1B9D" }}
                    align="center"
                  >
                    Total Complaints Resolved
                  </TableCell>
                  {/* <TableCell
                    className="fw-bold"
                    sx={{ color: "#3D1B9D" }}
                    align="center"
                  >
                    Average time to solve a complaint
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" scope="row">
                    {overviewsData && overviewsData.activeComplaints}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {overviewsData && overviewsData.totalComplaints}
                  </TableCell>
                  <TableCell align="center" scope="row">
                    {overviewsData && overviewsData.resolvedComplaints}
                  </TableCell>
                  {/* <TableCell align="center" scope="row">
                    20 hrs
                  </TableCell> */}
                </TableRow>

                <TableRow>
                  <TableCell align="center" scope="row">
                    <button
                      className="rounded-pill bg-light py-1 fw-bold shadow"
                      style={{
                        border: "2px solid #3D1B9D",
                        width: "50%",
                        fontSize: ".9rem",
                        color: "#3D1B9D",
                      }}
                      onClick={() => {
                        openInNewWindow("/activeComplaints");
                      }}
                    >
                      View
                    </button>
                  </TableCell>
                  <TableCell align="center" scope="row"></TableCell>
                  <TableCell align="center" scope="row">
                    <button
                      className="rounded-pill bg-light py-1 fw-bold shadow"
                      style={{
                        border: "2px solid #3D1B9D",
                        width: "50%",
                        fontSize: ".9rem",
                        color: "#3D1B9D",
                      }}
                      onClick={() => {
                        openInNewWindow("/resolvedComplaints");
                      }}
                    >
                      View
                    </button>
                  </TableCell>
                  <TableCell align="center" scope="row"></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Add Staff View Reports */}
      <div className="d-none d-flex justify-content-between px-5 my-5">
        <div>
          <button
            className="rounded-pill py-1 fw-bold shadow fs-5"
            style={{
              border: "2px solid #3D1B9D",
              width: "400px",
              fontSize: ".9rem",
              color: "white",
              backgroundColor: "#3D1B9D",
            }}
          >
            View Reports
          </button>
          <div className="my-2 fs-5" style={{ color: "#939393" }}>
            For particular department
          </div>
        </div>

        <div>
          <button
            className="rounded-pill py-1 fw-bold shadow fs-5"
            style={{
              border: "2px solid #3D1B9D",
              width: "400px",
              fontSize: ".9rem",
              color: "white",
              backgroundColor: "#3D1B9D",
            }}
          >
            Add/Remove staff
          </button>
          <div className="my-2 fs-5" style={{ color: "#939393" }}>
            For particular department
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
