import React, { useEffect, useRef, useState } from "react";
import * as Realm from "realm-web";
import "./EditStaffForm.css";
import CustomSelect from "../../CustomComponents/CustomSelect/CustomSelect";

function EditeStaffForm({ userDetails, closeEditStaffCallback }) {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  const [dashboardData, setDashboardData] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  // references
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const roleRef = useRef();
  const blockRef = useRef();

  // fetch dashboard data
  useEffect(() => {
    console.log(
      "dashboard-data",
      JSON.parse(sessionStorage.getItem("dashboard-data"))
    );
    setDashboardData(JSON.parse(sessionStorage.getItem("dashboard-data")));
  }, []);

  // fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      // make api call to fetch user details
      // adminData/getUser
      try {
        const res = await app.currentUser.callFunction("adminData/getUser", {
          email: userDetails.email,
          type: userDetails.type,
        });
        console.log("user details", res);

        setName(res.name);
        setEmail(res.email);
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserDetails();
  }, []);
  const onClickEditUser = async (e) => {
    // console.table(nameRef.current.value, phoneNumberRef.current.value, emailRef.current.value, roleRef.current.value, passwordRef.current.value, )
    e.preventDefault();
    const phoneNumber = phoneNumberRef.current.value;
    const role = roleRef.current.value;
    const blockName = blockRef.current.value;

    const nameSubstring = name.substring(0, 4);
    const phoneNumberSubstring = phoneNumber.substring(phoneNumber.length - 4);

    const password = nameSubstring + phoneNumberSubstring;

    try {
      if (name && phoneNumber) {
        const res = await app.currentUser.callFunction("adminData/editUser", {
          name,
          email: email.toLowerCase(),
          role,
          phoneNumber,
          blockName,
        });
        console.log("user edited successfully", res);
        //(
        //   role !== "Moderator" &&
        //     (await app.emailPasswordAuth.registerUser({ email, password }))
        // );
        // console.log("user registration successful");
      }
    } catch (err) {
      console.error(err.toString());
    }
    await closeEditStaffCallback();
  };

  return (
    <div className="d-flex justify-content-center">
      <form class="form shadow-none">
        <p class="title">Edit staff </p>
        {/* <p class="message">Signup now and get full access to our app. </p> */}

        <div className="d-flex justify-content-between">
          <div>
            <div class="input-group mb-3" style={{ width: "100%" }}>
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">
                  Role
                </label>
              </div>
              <select
                ref={roleRef}
                class="custom-select"
                id="inputGroupSelect01"
              >
                <option value="Admin">Admin</option>
                <option value="Head">Head</option>
                {app.currentUser.customData.level4 && (
                  <option value="Moderator">Moderator</option>
                )}
              </select>
            </div>
          </div>

          {/* Block Input */}
          <div>
            <div class="input-group mb-3" style={{ width: "100%" }}>
              <div class="input-group-prepend">
                <label class="input-group-text" for="inputGroupSelect01">
                  Block
                </label>
              </div>
              <select
                ref={blockRef}
                class="custom-select"
                id="inputGroupSelect01"
              >
                {dashboardData &&
                  dashboardData.blockName.map((block) => {
                    return <option value={block}>{block}</option>;
                  })}
              </select>
            </div>
          </div>
        </div>

        <label>
          <input
            onChange={(e) => {
              setName(e.target.value);
            }}
            required=""
            placeholder=""
            type="text"
            class="input"
            value={name}
          />
          <span>Name</span>
        </label>

        <label>
          <input
            value={email}
            required=""
            placeholder=""
            type="email"
            class="input"
          />
          <span>Email</span>
        </label>

        <label>
          <input
            onChange={(e) => setPhoneNumber(e.target.value)}
            ref={phoneNumberRef}
            required=""
            placeholder=""
            type="number"
            class="input"
          />
          <span>Phone</span>
        </label>

        {/* <label>
          <input required="" placeholder="" type="date" class="input" />
          <span>DOB</span>
        </label> */}

        {/* <label>
          <span>Default Password</span>
          <span className="mx-3">{`${name.substring(
            0,
            4
          )}-${phoneNumber.substring(phoneNumber.length - 4)}`}</span>
        </label> */}
        <button onClick={onClickEditUser} class="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default EditeStaffForm;
