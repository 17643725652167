import React, { useRef } from "react";
import "./ResetPassword.css";
import { useSearchParams } from "react-router-dom";
import * as Realm from "realm-web";
import Swal from "sweetalert2";

function ResetPassword() {
  const app = new Realm.App({ id: "national-admins-ullyb" });

  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const toggleNewPassword = () => {
    const inputElement = newPasswordRef.current;
    if (inputElement.type === "password") {
      inputElement.type = "text";
    } else {
      inputElement.type = "password";
    }
  };
  const toggleConfirmPassword = () => {
    const inputElement = confirmPasswordRef.current;
    if (inputElement.type === "password") {
      inputElement.type = "text";
    } else {
      inputElement.type = "password";
    }
  };

  const resetPasswordSubmit = async () => {
    const newPass = newPasswordRef.current.value;
    const confirmPass = confirmPasswordRef.current.value;

    if (newPass && confirmPass) {
      if (newPass === confirmPass) {
        // reset password
        const token = searchParams.get("token");
        const tokenId = searchParams.get("tokenId");
        if (token) {
          await app.emailPasswordAuth.resetPassword({
            password: confirmPass,
            token,
            tokenId,
          });

          Swal.fire(
            "Success!",
            "password reset successful! Go back to login page",
            "success"
          );
        } else {
          Swal.fire("Error!", "token not found", "error");
          console.error("token not found");
        }
      } else {
        Swal.fire("Error!", "passwords dont match!", "error");
        console.error("passwords dont match!");
      }
    } else {
      Swal.fire("Error!", "please enter new and confirm password", "error");
      console.error("please enter new and confirm password");
    }
  };
  return (
    <div className="row justify-content-center" style={{ height: "100vh" }}>
      <div className="col-sm-6 d-flex justify-content-center align-items-center">
        <div class="form">
          <p class="form-title fs-3 mt-3">Reset Password</p>
          <p
            className="text-center"
            style={{
              color: "#939393",
              marginTop: "-0.6rem",
              fontSize: "0.8rem",
            }}
          ></p>
          <div class="input-container rounded-3">
            <input
              ref={newPasswordRef}
              placeholder="Confirm Password"
              type="password"
            />

            <span onClick={toggleNewPassword} style={{ cursor: "pointer" }}>
              <svg
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                ></path>
              </svg>
            </span>
          </div>
          <div class="input-container rounded-3">
            <input
              ref={confirmPasswordRef}
              placeholder="Confirm Password"
              type="password"
            />

            <span onClick={toggleConfirmPassword} style={{ cursor: "pointer" }}>
              <svg
                stroke="currentColor"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                ></path>
                <path
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  stroke-width="2"
                  stroke-linejoin="round"
                  stroke-linecap="round"
                ></path>
              </svg>
            </span>
          </div>
          <button
            onClick={resetPasswordSubmit}
            style={{ backgroundColor: "#3D1B9D" }}
            class="submit"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
