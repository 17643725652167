import { Navigate, Route, Routes } from "react-router";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import LoginScreen from "./Components/Login/LoginScreen.jsx";
import Dashboard from "./Components/Dashboard/Dashboard.jsx";
import ResolvedComplaints from "./Components/ResolvedComplaints/ResolvedComplaints";
import * as Realm from "realm-web";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import ActiveComplaints from "./Components/ActiveComplaints/ActiveComplaints";

function App() {
  const app = new Realm.App({ id: "national-admins-ullyb" });
  console.log(app.currentUser);
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            sessionStorage.getItem("isAuthenticated") ? (
              <Navigate to="/dashboard" />
            ) : (
              <LoginScreen />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            sessionStorage.getItem("isAuthenticated") ? (
              <Dashboard />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="*"
          element={
            sessionStorage.getItem("isAuthenticated") ? (
              <Dashboard />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/resolvedComplaints"
          element={
            sessionStorage.getItem("isAuthenticated") ? (
              <ResolvedComplaints />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/activeComplaints"
          element={
            sessionStorage.getItem("isAuthenticated") ? (
              <ActiveComplaints />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/forgotpassword" element={<ResetPassword />} />
      </Routes>
    </Router>
  );
}

export default App;
